<template>
    <div class="laboratoryLDetails">
        <div class="Box">
            <van-nav-bar title="实验室概览" safe-area-inset-top  placeholder fixed left-text="返回" left-arrow  @click-left="$router.push('/laboratoryList')" />
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators='false'>
                <van-swipe-item v-for="(item, index) in FormData.I_ILChart" :key="index"> <img :src="item" alt=""> </van-swipe-item>
             
            </van-swipe>
            <div class="InfBox">
                <div class="TitleBox">
                    <div class="LetfBox">
                        <h4 class="TitleH4">{{FormData.I_Name}}</h4>
                        <p>{{FormData.I_No}}</p>
                    </div>
                    <div class="RigthBox">
                        <span> <i class="iconfont">&#xe600;</i> <i>{{FormData.I_Category}}</i></span>
                        <span> <i class="iconfont">&#xe69b;</i> <i>{{FormData.I_Level}}</i></span>
                    </div>
                </div>
                <div class="setionBox">
                    <span class="SpanBox"><i class="iconfont">&#xe6e9;</i> <i class="TetxTpis txt-cut">容纳人数{{FormData.I_PeopleNum}}人</i></span>
                    <span class="SpanBox"><i class="iconfont">&#xe614;</i> <i class="TetxTpis txt-cut">实验室面积{{FormData.I_Area}}m²</i></span>
                    <span class="SpanBox"><i class="iconfont">&#xe7f4;</i> <i class="TetxTpis txt-cut">楼宇地址<br/>{{FormData.I_Address}}</i> </span>
                   
                </div>
                <van-cell-group>
                    <van-cell title="实验室负责人" :value="FormData.I_Charger" />
                    <van-cell title="联系电话" :value="FormData.I_Phone" />
                    <van-cell title="开始使用时间" :value="FormData.I_StartTime | Datetime('yyyy-MM-dd')" />
                    <van-cell title="教学研究方向" :value="FormData.I_TRDirection" />
                    <van-cell title="所承担的实验课程" :value="FormData.I_Curriculum" />
                </van-cell-group>
                <div class="BoxRow">
                    <h5 class="van-hairline--bottom">危险类别</h5>
                    <p class="TextInput">{{FormData.I_RiskCategory}}</p>
                </div>
                <div class="BoxRow">
                    <h5 class="van-hairline--bottom">防护措施</h5>
                    <p class="TextInput">{{FormData.I_ProtectiveMeasures}}</p>
                </div>
                <div class="BoxRow">
                    <h5 class="van-hairline--bottom">灭火要点</h5>
                    <p class="TextInput">{{FormData.I_FireFightingPoints}}</p>
                </div>
                <div class="BoxRow">
                    <h5 class="van-hairline--bottom">基本情况简介</h5>
                    <p class="TextInput">{{FormData.I_Information}}</p>
                </div>
                <div class="BoxRow">
                    <h5>文件下载、预览</h5>
                    <div class="DwonLoad">
                        <a :href="FormData.I_IEList">仪器设备清单</a>
                        <a :href="FormData.I_BFList">内置家具设备清单</a>
                    </div>
                </div>
            </div>
          
        </div>
        <div class="InfButtom van-hairline--top">
            <!-- decodeURI -->
            <van-button  type="primary" block @click="$router.push({path:'/makeAppointment/laboratoryInfo',query:{I_ID:$route.params.id,fromPath:encodeURI($route.path)}})">马上预约</van-button>
        </div>
    </div>
</template>
<script>
import { Swipe, SwipeItem , Cell, CellGroup,Button ,NavBar} from 'vant';
import { Api_YyInformationController_GetInfo } from "@/api/laboratory";
export default {
    components:{
        [Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Button.name]: Button,
		[NavBar.name]: NavBar,
    },
    data() {
        return {
            FormData:{

            },
        }
    },
    created(){
        
     

        Api_YyInformationController_GetInfo({
            I_ID:this.$route.params.id
        }).then(res=>{
            this.FormData = res
 
        })
    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>
    .laboratoryLDetails {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .Box {
        flex: 1;
        overflow-y: auto;
        background-color: #f2f2f2;
        padding-bottom: 10px;
    }
    .InfBox {
        .TitleBox {
            background: #fff;
            padding:6px 15px;
            display: flex;
            flex-direction: row;
            .LetfBox {
                flex: 1;
                .TitleH4 {
                    font-size: 16px;
                    color: #333;
                    line-height: 28px;
                }
                p {
                    font-size: 15px;
                    color: #878787;
                }
            }
        }   
        .RigthBox {
            font-size: 11px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .iconfont {
                font-size: 18PX;
            }
            span:first-child {
                color: #ee0a24;
            }
             span:last-child {
                 color:#1989fa;
            }
        }
        .setionBox {
            background: #fff;
            display: flex;
            flex-direction: row;
            padding: 10px 0px;
            padding-right: 100px;
            padding-top: 3px;
            margin-bottom: 8px;
            .SpanBox {
                flex: 1;
                display: flex;
                flex-direction:column;
                align-items: center;
                padding: 0 15px;
                text-align: center;
                .iconfont {
                    font-size: 28px;
                    color: #878787;
                }
                .TetxTpis {
                    color:#404040;
                    font-size: 12px;
                }
            }
        }
    }
    .InfBoxLast {
        margin-bottom: 0px;
    }
    .my-swipe {
        .van-swipe-item {
            color: #fff;
            font-size: 20px;
            height: 200px;
            text-align: center;
            background-color: #39a9ed;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .InfButtom {
        padding: 10px 14px;
        background-color: #fff;
        .van-button {
            line-height: 38px;
            height: 38px;
        }
    }
    .BoxRow {
        font-size: 15px;
        padding: 0 15px;
        background: #fff;
        margin-top: 8px;
        h5 {
            line-height: 35px;
            color: #333;
            margin-bottom: 8px;
        }
        .TextInput {
            font-size: 14px;
            color: #444;
            padding-bottom: 8px;
            line-height: 22px;
        }
        .DwonLoad {
            display: flex;
            flex-direction: row;
            padding-bottom: 8px;
            a {
                flex: 1;
                font-size: 14px;
                padding-right: 8px;
                // text-align: center;
                color: #1989fa;
                text-decoration: underline;
                
            }
            
        }
    }
</style>